import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import userSaga from './user/saga';
import dashboardSaga from './dashboard/saga';
import managementSaga from './management/saga';
import transferSaga from './transfer/saga';
import postSaga from './post/saga';
import recentSaga from './recent/saga';
import requestSaga from './request/saga';
import profitSaga from './profit/saga';
import RMSaga from './rm/saga';
import FundSaga from './fund/saga';
import TaskSaga from './task/saga';


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        userSaga(),
        dashboardSaga(),
        managementSaga(),
        transferSaga(),
        postSaga(),
        recentSaga(),
        requestSaga(),
        profitSaga(),
        RMSaga(),
        FundSaga(),
        TaskSaga(),
    ]);
}
