// @flow
import {
    TASK_LIST,
    TASK_LIST_SUCCESS,
    TASK_LIST_FAILED,
    TASK_APPROVE,
    TASK_REJECT,
    TASK_CANCEL,
} from '../../constants/actionTypes';

type TaskAction = { type: string, payload: {} | string };

export const TaskList = (filter: string): TaskAction => ({
    type: TASK_LIST,
    payload: { filter }
});

export const TaskListSuccess = (TaskListResponse: any): TaskAction => ({
    type: TASK_LIST_SUCCESS,
    payload: TaskListResponse
});

export const TaskListFailed = (error: string): TaskAction => ({
    type: TASK_LIST_FAILED,
    payload: error
});

export const TaskApprove = (task_id: int, response_receive:any): TaskAction => ({
    type: TASK_APPROVE,
    payload: { task_id, response_receive }
});

export const TaskReject = (task_id: int, response_receive:any): TaskAction => ({
    type: TASK_REJECT,
    payload: { task_id, response_receive }
});

export const TaskCancel = (task_id: int, response_receive:any): TaskAction => ({
    type: TASK_CANCEL,
    payload: { task_id, response_receive }
});

