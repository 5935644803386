// @flow
import {
    RM_LIST,
    RM_LIST_SUCCESS,
    RM_LIST_FAILED,
    RM_INFO,
    RM_CREATE,
    RM_UPDATE,
    RM_USER_LIST,
    RM_USER_LIST_SUCCESS,
    RM_USER_LIST_FAILED,
    RM_USER_INFO,
    RM_USER_CREATE,
    RM_USER_UPDATE,
    RM_USER_WITHDRAW,
    RM_USER_PLAN_SUBSCRIBE,
    RM_USER_PLAN_TERMINATE,
    RM_REMOVE,
    RM_TEAM_LIST,
    RM_TEAM_LIST_SUCCESS,
    RM_TEAM_LIST_FAILED,
    RM_TEAM_INFO,
    RM_TEAM_UPDATE,
    RM_TEAM_CHANGE,
} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type RMAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const RM = (state:State = INIT_STATE, action: RMAction) => {
    switch (action.type) {
        case RM_LIST:
            return { ...state, loading: true };
        case RM_LIST_SUCCESS:
            return { ...state, RMListResponse: action.payload, loading: false, error: null };
        case RM_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RM_INFO:
            return { ...state, loading: true };
        case RM_CREATE:
            return { ...state, loading: true };
        case RM_UPDATE:
            return { ...state, loading: true };
        case RM_USER_LIST:
            return { ...state, loading: true };
        case RM_USER_LIST_SUCCESS:
            return { ...state, RMUserListResponse: action.payload, loading: false, error: null };
        case RM_USER_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RM_USER_INFO:
            return { ...state, loading: true };
        case RM_USER_CREATE:
            return { ...state, loading: true };
        case RM_USER_UPDATE:
            return { ...state, loading: true };
        case RM_USER_WITHDRAW:
            return { ...state, loading: false };
        case RM_USER_PLAN_SUBSCRIBE:
            return { ...state, loading: false };
        case RM_USER_PLAN_TERMINATE:
            return { ...state, loading: false };
        case RM_REMOVE:
            return { ...state, loading: false };
        case RM_TEAM_LIST:
            return { ...state, loading: true };
        case RM_TEAM_LIST_SUCCESS:
            return { ...state, RMTeamListResponse: action.payload, loading: false, error: null };
        case RM_TEAM_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RM_TEAM_INFO:
            return { ...state, loading: false };
        case RM_TEAM_UPDATE:
            return { ...state, loading: false };
        case RM_TEAM_CHANGE:
            return { ...state, loading: false };
        default: return { ...state };
    }
}

export default RM;

