import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Log from './log/reducers';
import User from './user/reducers';
import Dashboard from './dashboard/reducers';
import Management from './management/reducers';
import Transfer from './transfer/reducers';
import Post from './post/reducers';
import Recent from './recent/reducers';
import Request from './request/reducers';
import Profit from './profit/reducers';
import RM from './rm/reducers';
import Fund from './fund/reducers';
import Task from './task/reducers';

export default combineReducers({
    Auth,
    Log,
    User,
    Dashboard,
    Management,
    Transfer,
    Post,
    Recent,
    Request,
    Profit,
    RM,
    Fund,
    Task,
});
