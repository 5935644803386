// @flow
import {
    TASK_LIST,
    TASK_LIST_SUCCESS,
    TASK_LIST_FAILED,
    TASK_APPROVE,
    TASK_REJECT,
    TASK_CANCEL,
} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type TaskAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Task = (state:State = INIT_STATE, action: TaskAction) => {
    switch (action.type) {
        case TASK_LIST:
            return { ...state, loading: true };
        case TASK_LIST_SUCCESS:
            return { ...state, TaskListResponse: action.payload, loading: false, error: null };
        case TASK_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TASK_APPROVE:
            return { ...state, loading: true };
        case TASK_REJECT:
            return { ...state, loading: true };
        case TASK_CANCEL:
            return { ...state, loading: true };
        default: return { ...state };
    }
}

export default Task;

