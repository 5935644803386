// @flow
import {
    RM_LIST,
    RM_LIST_SUCCESS,
    RM_LIST_FAILED,
    RM_INFO,
    RM_CREATE,
    RM_UPDATE,
    RM_USER_LIST,
    RM_USER_LIST_SUCCESS,
    RM_USER_LIST_FAILED,
    RM_USER_INFO,
    RM_USER_CREATE,
    RM_USER_UPDATE,
    RM_USER_TERMINATE,
    RM_USER_RESET_PASSWORD,
    RM_USER_WITHDRAW,
    RM_USER_PLAN_SUBSCRIBE,
    RM_USER_PLAN_TERMINATE,
    RM_REMOVE,
    RM_TEAM_LIST,
    RM_TEAM_LIST_SUCCESS,
    RM_TEAM_LIST_FAILED,
    RM_TEAM_INFO,
    RM_TEAM_UPDATE,
    RM_TEAM_CHANGE,
} from '../../constants/actionTypes';

type RMAction = { type: string, payload: {} | string };

export const RMList = (): RMAction => ({
    type: RM_LIST,
    payload: { }
});

export const RMListSuccess = (RMListResponse: any): RMAction => ({
    type: RM_LIST_SUCCESS,
    payload: RMListResponse
});

export const RMListFailed = (error: string): RMAction => ({
    type: RM_LIST_FAILED,
    payload: error
});

export const RMInfo = (admin_id: int, email: string, response_receive:any): RMAction => ({
    type: RM_INFO,
    payload: { admin_id, email, response_receive }
});

export const RMCreate = (team_id: int, email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_CREATE,
    payload: { team_id, email, nickname, password, response_receive }
});

export const RMUpdate = (admin_id: int, nickname: string, withdrawal_address:string, response_receive:any): RMAction => ({
    type: RM_UPDATE,
    payload: { admin_id, nickname, withdrawal_address, response_receive }
});

export const RMUserList = (rm_id: int, email: string, sorting: string ): RMAction => ({
    type: RM_USER_LIST,
    payload: { rm_id, email, sorting }
});

export const RMUserListSuccess = (RMUserListResponse: any): RMAction => ({
    type: RM_USER_LIST_SUCCESS,
    payload: RMUserListResponse
});

export const RMUserListFailed = (error: string): RMAction => ({
    type: RM_USER_LIST_FAILED,
    payload: error
});

export const RMUserInfo = (user_id: int, email: string, response_receive:any): RMAction => ({
    type: RM_USER_INFO,
    payload: { user_id, email, response_receive }
});

export const RMUserCreate = (rm_id: int, email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_USER_CREATE,
    payload: { rm_id, email, nickname, password, response_receive }
});

export const RMUserUpdate = (user_id: int, email: string, nickname: string, password:string, response_receive:any): RMAction => ({
    type: RM_USER_UPDATE,
    payload: { user_id, email, nickname, password, response_receive }
});

export const RMUserTerminate = (user_id: int, subscription_id: int, response_receive:any): RMAction => ({
    type: RM_USER_TERMINATE,
    payload: { user_id, subscription_id, response_receive }
});

export const RMUserResetPassword = (user_id: int, response_receive:any): RMAction => ({
    type: RM_USER_RESET_PASSWORD,
    payload: { user_id, response_receive }
});

export const RMUserWithdraw = (user_id: int, amount: string, response_receive:any): RMAction => ({
    type: RM_USER_WITHDRAW,
    payload: { user_id, amount, response_receive }
});

export const RMUserPlanSubscribe = (user_id: int, lock_up_period:int, amount: string, name: string, response_receive:any): RMAction => ({
    type: RM_USER_PLAN_SUBSCRIBE,
    payload: { user_id, lock_up_period, amount, name, response_receive }
});

export const RMUserPlanTerminate = (user_id: int, plan_id: int, response_receive:any): RMAction => ({
    type: RM_USER_PLAN_TERMINATE,
    payload: { user_id, plan_id, response_receive }
});

export const RMRemove = (admin_id: int, new_rm_id: int, response_receive:any): RMAction => ({
    type: RM_REMOVE,
    payload: { admin_id, new_rm_id, response_receive }
});

export const RMTeamList = (): RMAction => ({
    type: RM_TEAM_LIST,
    payload: { }
});

export const RMTeamListSuccess = (RMTeamListResponse: any): RMAction => ({
    type: RM_TEAM_LIST_SUCCESS,
    payload: RMTeamListResponse
});

export const RMTeamListFailed = (error: string): RMAction => ({
    type: RM_TEAM_LIST_FAILED,
    payload: error
});

export const RMTeamInfo = (team_id: int, response_receive:any): RMAction => ({
    type: RM_TEAM_INFO,
    payload: { team_id, response_receive }
});

export const RMTeamUpdate = (team_id: int, head_id: int, response_receive:any): RMAction => ({
    type: RM_TEAM_UPDATE,
    payload: { team_id, head_id, response_receive }
});

export const RMTeamChange = (admin_id: int, team_id: int, response_receive:any): RMAction => ({
    type: RM_TEAM_CHANGE,
    payload: { admin_id, team_id, response_receive }
});

